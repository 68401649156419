import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <header
      className="fixed top-0 left-0 w-full bg-transparent backdrop-blur-lg z-50"
      style={{
        height: "60px",
        boxSizing: "border-box",
      }}
    >
      <div className="flex justify-between items-center px-4 md:px-10 lg:px-20">
        {/* Logo */}
        <Link
          to="/"
          className="site-brand w-nav-brand"
          style={{
            height: "60px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <video
            src="/crackiit.mp4"
            className="site-logo"
            alt="CrackIIT logo"
            style={{
              height: "100px",
              width: "auto",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            autoPlay
            loop
            muted
          />
        </Link>

        {/* Navigation Menu */}
        <nav className="hidden md:flex items-center space-x-6">
          <Link to="/" className="nav-link text-white hover:text-yellow-300">
            Home
          </Link>
          <Link to="/mission-vision" className="nav-link text-white hover:text-yellow-300">
            The Vision
          </Link>
          <Link
            to="/masterfeature"
            className="nav-link text-white hover:text-yellow-300"
          >
            Ask Doubt ₹9 Star Plan
          </Link>
          <Link to="/about" className="nav-link text-white hover:text-yellow-300">
            About
          </Link>
          <Link
            to="/mentors"
            className="nav-link text-white hover:text-yellow-300"
          >
            Connect with IITian
          </Link>
          <Link to="/pricing" className="nav-link text-white hover:text-yellow-300">
            Courses
          </Link>
          <Link
            to="/studybuddy"
            className="nav-link text-white hover:text-yellow-300"
          >
            Study Buddy
          </Link>
          
          <Link to="/contact" className="nav-link text-white hover:text-yellow-300">
            Contact
          </Link>
        </nav>

        {/* Sign In Button */}
        <Link
          to="/login"
          className="hidden md:block bg-gradient-to-r from-purple-600 to-purple-800 text-white px-4 py-2 rounded-lg shadow-md hover:from-purple-800 hover:to-purple-600 transition duration-300 transform hover:scale-105"
        >
          Sign In
        </Link>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
            style={{
              fontSize: "2.5rem", // Increase the font size
              padding: "0.5rem 1rem", // Add padding for better usability
            }}
          >
            ☰
          </button>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {menuOpen && (
        <div
          className="flex flex-col bg-black bg-opacity-80 w-full p-4 md:hidden"
        >
          <Link to="/" className="block text-white py-2 hover:text-yellow-300">
            Home
          </Link>
          <Link to="/mission-vision" className="block text-white py-2 hover:text-yellow-300">
            The Vision
          </Link>
          <Link
            to="/masterfeature"
            className="block text-white py-2 hover:text-yellow-300"
          >
            Ask Doubt ₹9 Star Plan
          </Link>
          <Link to="/about" className="block text-white py-2 hover:text-yellow-300">
            About
          </Link>
          <Link
            to="/mentors"
            className="block text-white py-2 hover:text-yellow-300"
          >
            Connect with IITian
          </Link>
          <Link
            to="/pricing"
            className="block text-white py-2 hover:text-yellow-300"
          >
            Courses
          </Link>
          <Link
            to="/studybuddy"
            className="block text-white py-2 hover:text-yellow-300"
          >
            Study Buddy
          </Link>
          <Link
            to="/contact"
            className="block text-white py-2 hover:text-yellow-300"
          >
            Contact
          </Link>
          <Link
            to="/login"
            className="block bg-gradient-to-r from-purple-600 to-purple-800 text-white py-2 text-center rounded-lg shadow-md hover:from-purple-800 hover:to-purple-600 transition duration-300 transform hover:scale-105"
          >
            Sign In
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;