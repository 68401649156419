import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

const HtmlLoader: React.FC = () => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const [isVideoVisible, setIsVideoVisible] = useState(true);

  const cards = [
    {
      icon: "🤝",
      title: "Connect with IITian and discuss Qs with him to improve your thinking process",
    },
    {
      icon: "🧠",
      title: "Observe how IITians think to solve the Questions",
    },
    {
      icon: "🔍",
      title: "Help me find my mistakes - test or overall where I am lacking",
    },
  ];

  
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVideoVisible(true);
        } else {
          setIsVideoVisible(false);
        }
      });
    }, observerOptions);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const [htmlContent, setHtmlContent] = useState<string>("");

  useEffect(() => {
    fetch("/LandingPage3.html") // File should be in the `public` directory
      .then((response) => response.text())
      .then((data) => setHtmlContent(data));
  }, []);

  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const sentenceRefs = [useRef<HTMLParagraphElement>(null), useRef<HTMLParagraphElement>(null), useRef<HTMLParagraphElement>(null)];

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sentenceRefs.findIndex((ref) => ref.current === entry.target);
          setHighlightedIndex(index);
        }
      });
    }, observerOptions);

    sentenceRefs.forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      sentenceRefs.forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, [sentenceRefs]);

  return (
    <>
      {/* Background Video */}
      <div className=" video fixed top-0 left-0 w-full h-full -z-20">
        <video
          className="w-full h-full object-cover"
          src="/herosection.mp4"
          autoPlay
          loop
          muted
        ></video>
      </div>

      <div className="relative w-full">
        <main className="main-container container mx-auto px-4 py-6">
          {/* Hero Section */}
          <section className="text-center mb-5 heading">
            <h1 className="text-2xl md:text-5xl font-bold mb-4 text-white">YOUR IIT GAME CHANGER</h1>
            <p className="text-lg md:text-xl text-white mb-4">
              Finally, no more waiting to get the help you need!
            </p>
          </section>

{/* Buttons Section */}
<section className="text-center mb-10 flex flex-col sm:flex-row justify-center items-center gap-6">
            <button
              onClick={() => (window.location.href = "/masterfeature")}
              className="bg-gradient-to-r from-purple-600 to-purple-800 text-white px-6 py-3 rounded-lg shadow-md hover:from-purple-800 hover:to-purple-600 transition duration-300 transform hover:scale-105"
            >
              Click here to Ask doubts from IITians @ ₹9
            </button>
            <button
              onClick={() => (window.location.href = "/mentors")}
              className="bg-gradient-to-r from-purple-600 to-purple-800 text-white px-6 py-3 rounded-lg shadow-md hover:from-purple-800 hover:to-purple-600 transition duration-300 transform hover:scale-105"
            >
              Click to Connect with IITians @ ₹99
            </button>
            <button
              onClick={() => (window.location.href = "/pricing")}
              className="bg-gradient-to-r from-purple-600 to-purple-800 text-white px-6 py-3 rounded-lg shadow-md hover:from-purple-800 hover:to-purple-600 transition duration-300 transform hover:scale-105"
            >
              Click here to Get started for free
            </button>
          </section>
          

           {/* Spacer to avoid overlap */}
<div className="spacer" style={{ height: '5.7rem' }}></div> 
          
 {/* Spacer to avoid overlap */}
 {/* <div className="spacer" style={{ height: '3.9rem' }}></div>   */}


          <div className="relative2 h-auto">
  <div className="dog">
    <img src="/social-paws.png" alt="Cool dog with sunglasses" />
  </div>
  <div className="absolute w-full h-full grid grid-cols-2 grid-rows-2 gap-4">
    <div className="card">
      <h3>1:1 DISCUSSIONS WITH MENTORS</h3>
      <p>Your mentor will guide you like your elder brother</p>
    </div>
    <div className="card">
      <h3>EXPERT SUPPORT</h3>
      <p>Ask as many questions as you like, to as many teachers as you like.</p>
    </div>
    <div className="card">
      <h3>PAPER SOLVING STRATEGY</h3>
      <p>Live JEE paper solving by IITians.</p>
    </div>
    <div className="card">
      <h3>Find your study buddy</h3>
      <p>Find new aspirants. Connect, ask doubts & study with each other.</p>
    </div>
  </div>
</div>



  {/* Spacer to avoid overlap */}
<div className="spacer" style={{ height: '17rem' }}></div>  

 

{/* Key Features Section */}
<section className="blog-section my-16">
            <div className="container">
              <div className="section-header mb-8">
                <div className="section-title-wrapper">
                  <h2 className="section-title text-white text-center">KEY FEATURES OF CrackIIT</h2>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {[
                  {
                    title: "Secret Tips n Tricks by your Elder Brother like Mentor",
                    img: "tricks.png",
                    
                  },
                  {
                    title: "Find your Study Buddy",
                    img: "/studybuddy.jpg",
                    
                  },
                  {
                    title: "Test Analysis with your Mentor",
                    img: "/testAnalysis.jpg",
                   
                  },
                  {
                    title: "Steps to organize your daily activities",
                    img: "/organise.png",
                    
                  },
                  {
                    title: "Continuous tracking of progress and making strategies of it",
                    img: "/track.png",
                    
                  },
                  {
                    title: "Paper solving tips",
                    img: "/paper.png",
                    
                  },
                ].map((feature, index) => (
                  <motion.a
                    key={index}
                    className="blog-column rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300 bg-gradient-to-r text-purpe p-6"
                  >
                    <img src={feature.img} alt={feature.title} className="w-full rounded-lg mb-4" />
                    <h3 className="font-bold text-white text-xl">{feature.title}</h3>
                  </motion.a>
                ))}
              </div>
            </div>
          </section>
        


 {/* Scroll-based Highlight Section */}
 <section className="text-center my-24">
            {sentenceRefs.map((ref, index) => (
              <p
                key={index}
                ref={ref}
                className={`text-2xl mb-4 transition-colors duration-500 ${
                  highlightedIndex === index ? "text-yellow-300 font-bold" : "text-white"
                }`}
              >
                {index + 1}:{" "}
                {index === 0
                  ? "Most AFFORDABLE DOUBT SOLVING & MENTORSHIP PLATFORM!"
                  : index === 1
                  ? "Achieve your IIT dream with dedicated support by IITians."
                  : "Get ahead with extra POLISH from IITians support."}
              </p>
            ))}
          </section>   



          <section className="flex flex-wrap justify-center gap-6 my-12">
        {cards.map((card, index) => (
          <motion.div
            key={index}
            className="card flex flex-col justify-center items-center p-6 rounded-lg shadow-lg transform duration-300 hover:scale-105"
            style={{
              minWidth: "300px",
              maxWidth: "350px",
              height: "auto",
            }}
          >
            <div className="text-4xl mb-4">{card.icon}</div>
            <p className="text-lg font-semibold text-center">{card.title}</p>
          </motion.div>
        ))}
      </section>



       


          <section className="text-center mb-5 flex justify-center items-center">
  <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">SECRET TIPS & STRATEGIES BY IITian</h1>
</section>

<section className="text-center mb-5 flex justify-center items-center">
  <h1 className="text-xl md:text-5xl font-bold mb-4 text-white">From AVERAGE to IIT JOURNEY</h1>
</section>


     {/* YouTube Video Section */}
<section
  ref={videoRef}
  className="video-container flex justify-center items-center"
  style={{ minHeight: "500px", width: "100vw" }} // Ensure full width and height
>
  <div
    className="w-full h-full relative" // Ensure it stretches to the parent container
    style={{ paddingTop: "56.25%" }} // Maintain aspect ratio (16:9)
  >
    <iframe
      className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
      src="https://www.youtube.com/embed/je0-4yZ5wbo"
      title="YouTube Video"
      allow="autoplay; encrypted-media; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
</section>



         
 {/* Spacer to avoid overlap */}
 <div className="spacer" style={{ height: '5rem' }}></div> 

          <section className="mb-16">
  <h2 className="text-3xl font-bold text-center text-white mb-8">What Our Students Say</h2>
  
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
    {[
      {
        name: "SALONI",
        feedback: "I USED TO SCORE 120/300 BUT AFTER REGULAR GUIDANCE & DOUBT SESSIONS FROM IITIANS, MY SCORE HAS INCREASED TO 200+. The mentorship and doubt-solving support have been amazing! In coaching classes, I have to wait for my turn to ask doubts because other students are also asking their queries to the teacher. But on CrackIIT, doubts are resolved instantly. Plus, since doubts are solved from home, it saves the time spent traveling to coaching classes. I feel much more confident in tackling challenging problems.",
        image: "/sal.png",
      },
      {
        name: "AYUSH",
        feedback: "MY JEE MOCKS SCORE IN MATHS HAS INCREASED FROM -2 TO 50+ Live discussions with mentors have completely changed how I approach JEE preparation. The courses are quite unique and affordable. The mentorship sessions and doubt-clearing support are absolutely top-notch.",
        image: "/ayu.png",
      },
      {
        name: "HARSHEE",
        feedback: "CrackIIT has been a GAME CHANGER for me. My mock test scores have improved significantly because of it. In coaching classes, there are many students, so I often hesitate to ask my doubts in front of everyone. But on CrackIIT, IITians clear my doubts one-on-one.",
        image: "/har.png",
      },
      {
        name: "MANAN",
        feedback: "The platform's study buddy feature helped me find a like-minded partner to practice with!",
        image: "/man.png",
      },
    ].map((testimonial, index) => (
      <motion.div
        key={index}
        className="bg-gradient-to-r from-purple-600 to-purple-800 rounded-lg p-8 shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300 text-center text-white"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        {/* Enlarged Circular Profile Image */}
        <img
          src={testimonial.image}
          alt={`${testimonial.name}'s photo`}
          className="w-32 h-32 rounded-full mx-auto mb-6 border-4 border-white object-cover"
        />

        {/* Student Name */}
        <h3 className="font-bold text-xl mb-2">{testimonial.name}</h3>
        
        {/* Feedback Text */}
        <p className="text-sm leading-relaxed">{testimonial.feedback}</p>
      </motion.div>
    ))}
  </div>
</section>





          {/* Comparison Chart Section */}
          <section className="mb-24">
            <h2 className="text-3xl font-bold mb-8 text-center text-white">Feature Comparison</h2>
            <div className="overflow-x-auto">
              <table className="w-full text-left text-white">
                <thead>
                  <tr className="border-b border-white">
                    <th className="p-4">Feature</th>
                    <th className="p-4">Our Platform</th>
                    <th className="p-4">Other Platforms</th>
                    <th className="p-4">Free Resources</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    { feature: "Academic Doubts solving support", free: false, other: true, our: true },
                    { feature: "Affordable and Efficient", free: false, other: false, our: true },
                    { feature: "Test Analysis with mentor", free: false, other: false, our: true },
                    {
                      feature: "Find your Study Buddy",
                      free: false,
                      other: false,
                      our: true,
                    },
                    {
                      feature: "Tricks to solve Tricky Qs from Mentor",
                      free: false,
                      other: false,
                      our: true,
                    },
                  ].map((row, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-gray-900" : ""}>
                      <td className="p-4">{row.feature}</td>
                      <td className="p-4">{row.our ? "✓" : "✗"}</td>
                      <td className="p-4">{row.other ? "✓" : "✗"}</td>
                      <td className="p-4">{row.free ? "✓" : "✗"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

    
 {/* YouTube Video Section */}

  
 <section className="text-center mb-5 flex justify-center items-center">
  <h1 className="text-4xl md:text-5xl font-bold mb-4 text-white">CRACKED IIT WITH REGULAR SCHOOLING</h1>
</section>        
      {/* YouTube Video Section */}
<section
  ref={videoRef}
  className="video-container flex justify-center items-center"
  style={{ minHeight: "500px", width: "100vw" }} // Ensure full width and height
>
  <div
    className="w-full h-full relative" // Ensure it stretches to the parent container
    style={{ paddingTop: "56.25%" }} // Maintain aspect ratio (16:9)
  >
    <iframe
      className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
      src="https://www.youtube.com/embed/cOdSZtsdpUg?autoplay=1&mute=1"
      title="YouTube Video"
      allow="autoplay; encrypted-media; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
</section>


        </main>

        {/* Injected HTML */}
  <div
    className="dynamic-html-content mt-8 relative"
    dangerouslySetInnerHTML={{ __html: htmlContent }}
  ></div>
      </div>

     
    </>
  );
};

export default HtmlLoader;