import React from "react";

const MissionVision: React.FC = () => {
  return (
    <div className="relative min-h-screen text-white">
      {/* Background Video */}
      <div className="video-containerhero video fixed top-0 left-0 w-full h-full -z-10">
        <video
          autoPlay
          loop
          muted
           className="w-full h-full object-cover"
        >
          <source src="/herosection.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-16 relative z-20">
        <h1 className="text-4xl font-bold text-center mb-8 text-purple-300">
          Our Mission and Vision
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Mission Card */}
          <div className="relative bg-gradient-to-br from-purple-800 to-purple-600 p-8 rounded-3xl shadow-lg text-white">
            <h2 className="text-2xl font-bold mb-4 text-purple-300">Our Mission</h2>
            <p className="text-sm">
              Our mission is to EMPOWER UNDERPRIVILEGED students from BPL (Below Poverty Line) and EWS. Our platform provides free education, mentorship & guidance from Expert Mentors like IITians to these deserving students, helping them achieve their dreams of securing admission to IITs & other esteemed colleges.
              
              
               
            </p>
          </div>

          {/* Vision Card */}
          <div className="relative bg-gradient-to-br from-purple-800 to-purple-600 p-8 rounded-3xl shadow-lg text-white">
            <h2 className="text-2xl font-bold mb-4 text-purple-300">Our Vision</h2>
            <p className="text-sm">
            Our VISION is to create a MEANINGFUL SOCIAL IMPACT by ensuring equitable Education & Mentorship from IITian Mentors to all students, REGARDLESS OF THEIR FINANCIAL BACKGROUND.
            
            </p>
          </div>



           {/* Mission Card */}
           <div className="relative bg-gradient-to-br from-purple-800 to-purple-600 p-8 rounded-3xl shadow-lg text-white">
            {/* <h2 className="text-2xl font-bold mb-4 text-purple-300">Our Mission</h2> */}
            <p className="text-sm">
              
              In addition, we offer AFFORDABLE education and mentorship to general students, ensuring that quality mentorship is accessible to all, as there are many students who are talented BUT DUE TO LACK OF MENTORSHIP THEY ARE UNABLE TO ACHIEVE WHAT THEY DESERVE. 
              
               We strive to simplify complex concepts and make quality education accessible to everyone,
              helping students achieve their dreams of joining prestigious institutions.
            </p>
          </div>

          {/* Vision Card */}
          <div className="relative bg-gradient-to-br from-purple-800 to-purple-600 p-8 rounded-3xl shadow-lg text-white">
            {/* <h2 className="text-2xl font-bold mb-4 text-purple-300">Our Vision</h2> */}
            <p className="text-sm">
           
              Our vision is to build a future where every student has equal access
              to high-quality mentorship and guidance, irrespective of their location
              or background. We aim to foster a community of learners and achievers
              driven by a shared passion for excellence and innovation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVision;